import styles from './Login.module.css';
import dsd_icon from '../../../../media_dsd/dsd_icon.png'
import { Button } from "@mui/material";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from '../../../../app/modules/auth'
import {
    useEffect,
} from 'react'

const SignedOut = () => {
    const { currentUser, logout } = useAuth()

    useEffect(() => {
        logout();
    }, []);

    const handleMicrosoftSSOLogin = () => {
        window.location.href = '/oauth2/login';
    };

    return (
        <div className={styles.container}>
            <div
                className='form w-100 mt-7'
            >
                <div className='text-center mb-7'>
                    <img className={styles.imgHeight} src={dsd_icon} alt="DSD icon" />
                    <h3 className='text-dark fw-bolder mb-7 mt-4'>You have been signed out successfully</h3>
                    <Button variant="outlined" sx={{ height: '40px', textTransform: 'none', fontSize: '14px', mt: 3 }} onClick={handleMicrosoftSSOLogin} startIcon={<img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/microsoft-icon.png')}
                        height='30'
                        width='30'
                    />}>
                        Continue to Sign In
                    </Button>
                </div>
            </div>
        </div>
    )
}

export { SignedOut }
