import styles from './chatlogStyles.module.scss'
import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, FormHelperText, CircularProgress, Tooltip, Pagination, Stack, Checkbox, Chip, } from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import Accordion from 'react-bootstrap/Accordion';
import SmartToySharpIcon from '@mui/icons-material/SmartToySharp';
import { encrypt, User, getAllUsers, getAllAgents, fetchLoginCookie, decryptData, AgentCardResponse, getChatLogByUser, ChatLogByPageResponse, getCitationFilePath, getChatLogByAgents, ProjectResponse, getAllProjects, getChatLogByProject, ChatLogWithAgentResponse, getChatLog, getOneAgent, getOneAgentForMagicLink } from '../../../../api';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as XLSX from 'xlsx';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import Feedbacks from '../../../../_metronic/partials/chat/Feedback';
import FilterListIcon from '@mui/icons-material/FilterList';
import { KTSVG } from '../../../../_metronic/helpers';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import DOMPurify from 'dompurify';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';
import { useLocation } from 'react-router-dom';
// import remarkMath from 'remark-math';
// import rehypeKatex from 'rehype-katex';
interface Top3Fields {
    agent_name: string | null;
    question: string;
    response: string;
    created_on: string;
    created_by: string;
    vote_timestamp: string;
    upvote: boolean;
    downvote: boolean;
    comments: string;
}


export const ChatLog = () => {

    const location = useLocation()
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [userToAdd, setUserToAdd] = useState<{ id: number, email: string }[]>([]);
    // const [userIds, setUserIds] = useState<number[]>([]);
    // const [agentToAdd, setAgentToAdd] = useState<string>("");
    const [agentToAdd, setAgentToAdd] = useState<{ id: number, name: string }[]>([]);
    const queryParams = new URLSearchParams(location.search)
    const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')))
    // const [agentIds, setAgentIds] = useState<number[]>([]);
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [allAgents, setAllAgents] = useState<AgentCardResponse[]>([]);
    const [filteredAgents, setFilteredAgents] = useState<AgentCardResponse[]>([]);
    const [chatLog, setChatLog] = useState<any>([]);
    const [selected, setSelected] = useState<string>("user");
    const [selectedLiked, setSelectedLiked] = useState<string>("all");
    const [selectedCommented, setSelectedCommented] = useState<string>("all");
    const [selectedQuerySource, setSelectedQuerySource] = useState<string>("all");
    const [chatLoading, setChatLoading] = useState<boolean>(false);
    const [top3FieldsData, setTop3FieldsData] = useState<Top3Fields[]>([]);
    const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
    const filterOptions = ["user", "agent", "project"]
    const likedFilterOptions = ["all", "good response", "bad response"]
    const commentedFilterOptions = ["all", "feedback", "no feedback"]
    const querySourceFilterOptions = ["all","web", "MS Teams", "Chat Widget"]
    const [selectedChatIndex, setSelectedChatIndex] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number>();
    const [addFilter, setAddFilter] = useState<boolean>(false);
    const [filteredChatLog, setFilteredChatLog] = useState<any>([]);
    const [inputValues, setInputValue] = useState<string>('');
    const [userInputValue, setUserInputValue] = useState<string>('');
    const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [projectToAdd, setProjectToAdd] = useState<{ id: number, name: string }[]>([]);
    const [projectInputValue, setProjectInputValue] = useState<string>('');
    const [filteredProjects, setFilteredProjects] = useState<ProjectResponse[]>([]);
    const [allProjects, setAllProjects] = useState<ProjectResponse[]>([]);
    const [userEmail, setUserEmail] = useState<string>();

    const handleAgentCheckboxChange = (agentName: string, agentId: number) => {
        setAgentToAdd((prevAgentToAdd) => {
            // Check if the current agent is already added by matching the id
            const isAgentAlreadyAdded = prevAgentToAdd.some(agent => agent.id === agentId);

            if (isAgentAlreadyAdded) {
                // Filter out the agent from the array by id
                return prevAgentToAdd.filter(agent => agent.id !== agentId);
            } else {
                // Add the new agent object to the array
                return [...prevAgentToAdd, { id: agentId, name: agentName }];
            }
        });
    };

    const handleUserCheckboxChange = (email: string, userId: number) => {
        setUserToAdd((prevUserToAdd) => {
            // Check if the current agent is already added by matching the id
            const isUserAlreadyAdded = prevUserToAdd.some(user => user.id === userId);

            if (isUserAlreadyAdded) {
                // Filter out the agent from the array by id
                return prevUserToAdd.filter(agent => agent.id !== userId);
            } else {
                // Add the new agent object to the array
                return [...prevUserToAdd, { id: userId, email: email }];
            }
        });
    };
    const handleProjectCheckboxChange = (projectName: string, projectId: number) => {
        setProjectToAdd((prevProjectToAdd) => {
            // Check if the current project is already added by matching the id
            const isProjectAlreadyAdded = prevProjectToAdd.some(project => project.id === projectId);

            if (isProjectAlreadyAdded) {
                // Filter out the project from the array by id
                return prevProjectToAdd.filter(project => project.id !== projectId);
            } else {
                // Add the new project object to the array
                return [...prevProjectToAdd, { id: projectId, name: projectName }];
            }
        });
    };

    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        setUserEmail(decryptedRes?.user)
        const orgId = parseInt(decryptedRes?.org_id);
        fetchAllUsers(orgId)
        fetchAllAgents()
        fetchAllProjects()
    }, [])

    const reset = () => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const orgId = parseInt(decryptedRes?.org_id);

        setUserToAdd([])
        // setUserIds([])
        // setAgentIds([])
        setAgentToAdd([])
        fetchAllUsers(orgId)
        fetchAllAgents()
        fetchAllProjects()
        setFilteredAgents(allAgents)
        setFilteredUsers(allUsers)
        setFilteredProjects(allProjects)
        setChatLog([])
        setSelected("user")
        setSelectedLiked("all")
        setSelectedCommented("all")
        setSelectedQuerySource("all")
        setUserInputValue('')
    }

    useEffect(() => {
        const agentIds = agentToAdd ? agentToAdd.map(agent => agent.id) : [];
        const userIds = userToAdd ? userToAdd.map(user => user.id) : [];
        const projectIds = projectToAdd ? projectToAdd.map(project => project.id) : [];
        fetchAllChatLog(userIds, agentIds, projectIds, selectedLiked, selectedCommented, selectedQuerySource);
        if (!(selected === "user" || selected === "project")) {
            setFilteredAgents(allAgents)
        }
        if (!(selected === "agent" || selected === "project"))
            setFilteredUsers(allUsers)
        if (!(selected === "agent" || selected === "user"))
            setFilteredProjects(allProjects)

    }, [userToAdd, agentToAdd, selectedCommented, selectedLiked, projectToAdd, selectedQuerySource])

    useEffect(() => {
        if (selected === "user") {
            setAgentToAdd([])
            setProjectToAdd([]);
            setFilteredUsers(allUsers)
        } else if (selected === "agent") {
            setUserToAdd([])
            setProjectToAdd([]);
            setFilteredAgents(allAgents)
        }
        else if (selected === 'project') {
            setUserToAdd([]);
            setAgentToAdd([]);
            setFilteredProjects(allProjects);
        }
        setChatLog([])
    }, [selected])

    const fetchAllChatLog = async (userIds: number[], agentId: number[], projectId: number[], selectedLiked?: string, selectedCommented?: string, selectedQuerySource?: string) => {
        setChatLoading(true)
        let response, filterResponse: ChatLogByPageResponse;
        let chatlog, filterChatlog: ChatLogWithAgentResponse[] = [];
        if (selected === "user") {
            if (userIds && userIds.length > 0) {
                filterResponse = await getChatLogByUser(userIds, 1)
                response = await getChatLog(userIds, projectId, agentId, 1)
                chatlog = response.chatlogs;
                filterChatlog = filterResponse.chatlogs;
                setTotalPages(response.num_pages);
                const userAgents = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.id));
                const userProjects = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.project));
                const filteredAgents = allAgents.filter(agent => userAgents.has(agent.id));
                const filteredProjects = allProjects.filter(project => userProjects.has(project.id));
                setFilteredAgents(filteredAgents.length > 0 ? filteredAgents : []);
                setFilteredProjects(filteredProjects.length > 0 ? filteredProjects : []);
            } else {
                chatlog = [];
            }
        }
        else if (selected === "agent") {
            if (agentId && agentId.length > 0) {
                filterResponse = await getChatLogByAgents(agentId, 1)
                response = await getChatLog(userIds, projectId, agentId, 1)
                chatlog = response.chatlogs;
                filterChatlog = filterResponse.chatlogs;
                setTotalPages(response.num_pages);
                const agentUsers = new Set(filterChatlog.map(filterchatlog => filterchatlog.user));
                const userProjects = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.project));
                const filteredUsers = allUsers.filter(user => agentUsers.has(user.id));
                const filteredProjects = allProjects.filter(project => userProjects.has(project.id));
                setFilteredUsers(filteredUsers.length > 0 ? filteredUsers : []);
                setFilteredProjects(filteredProjects.length > 0 ? filteredProjects : []);
            } else {
                chatlog = [];
            }
        }
        else if (selected === "project") {
            if (projectId && projectId.length > 0) {
                filterResponse = await getChatLogByProject(projectId, 1)
                response = await getChatLog(userIds, projectId, agentId, 1)
                chatlog = response.chatlogs;
                filterChatlog = filterResponse.chatlogs;
                setTotalPages(response.num_pages);
                const projectUsers = new Set(filterChatlog.map(filterchatlog => filterchatlog.user));
                const projectAgents = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.id));
                const filteredUsers = allUsers.filter(user => projectUsers.has(user.id));
                const filteredAgents = allAgents.filter(agent => projectAgents.has(agent.id));
                setFilteredUsers(filteredUsers.length > 0 ? filteredUsers : []);
                setFilteredAgents(filteredAgents.length > 0 ? filteredAgents : []);
            } else {
                chatlog = [];
            }
        }
        const mappedData = chatlog.map(item => {
            const matchingAgent = allAgents.find(agent => agent.id === item.agent.id);
            const hasUpvoteOrDownvote =
                item.feedback && Array.isArray(item.feedback) && item.feedback.some(feedback => feedback.is_upvote || feedback.is_downvote);

            return {
                ...item,
                agent_name: matchingAgent ? matchingAgent.agent_name : null,
                hasUpvoteOrDownvote: hasUpvoteOrDownvote,
                hasComments: item.comments && Array.isArray(item.comments) && item.comments.length > 0,
            };
        })

        setChatLog(mappedData)
        setChatLoading(false)
    }

    const fetchAllUsers = async (orgId: number) => {
        const response = await getAllUsers(orgId);
        setAllUsers(response)
        setFilteredUsers(response)
    }

    const fetchAllAgents = async () => {
        const response = await getAllAgents();
        const activeAgents = response.filter(agent => agent.status !== 'Draft');
        setAllAgents(activeAgents)
        setFilteredAgents(activeAgents)
    }
    const fetchAllProjects = async () => {
        const response = await getAllProjects();
        setAllProjects(response)
        setFilteredProjects(response)
    }
    const handleFilter = (event: SelectChangeEvent<typeof selected>) => {
        const {
            target: { value },
        } = event;
        setSelected(value)
    };
    const handleLikedFilter = (event: SelectChangeEvent<typeof selectedLiked>) => {
        const {
            target: { value },
        } = event;
        setSelectedLiked(value)
    };
    const handleCommentedFilter = (event: SelectChangeEvent<typeof selectedCommented>) => {
        const {
            target: { value },
        } = event;
        setSelectedCommented(value)
    };

    const handleQueryFilter = (event: SelectChangeEvent<typeof selectedQuerySource>) => {
        const {
            target: { value },
        } = event;
        setSelectedQuerySource(value)
    };

    const isOptionEqualToValue = (option, value) => {
        return option.endsWith(value); // Compare options based on email part
    };

    const exportToExcel = (data, filename: string) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${filename}.xlsx`);  // Use .xlsx extension instead of .xls
    };

    const handleFeedbackClick = (index: number) => {
        setSelectedChatIndex(index);
        setShowCommentModal(true);
    };
    const handlePageChange = async (page) => {
        setCurrentPage(page);
        setChatLoading(true)
        let response, filterResponse: ChatLogByPageResponse;
        let chatlog, filterChatlog: ChatLogWithAgentResponse[] = [];
        const userIds = userToAdd.map(user => user.id);
        const agentIds = agentToAdd.map(agent => agent.id);
        const projectIds = projectToAdd.map(project => project.id);
        if (selected === "user") {
            const userIds = userToAdd.map(user => user.id);

            if (userIds && userIds.length > 0) {
                filterResponse = await getChatLogByUser(userIds, page)
                response = await getChatLog(userIds, projectIds, agentIds, page)
                chatlog = response.chatlogs;
                filterChatlog = filterResponse.chatlogs;
                setTotalPages(response.num_pages);
                const userAgents = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.id));
                const userProjects = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.project));
                const filteredAgents = allAgents.filter(agent => userAgents.has(agent.id));
                const filteredProjects = allProjects.filter(project => userProjects.has(project.id));
                setFilteredAgents(filteredAgents.length > 0 ? filteredAgents : []);
                setFilteredProjects(filteredProjects.length > 0 ? filteredProjects : []);
            }
        }
        else if (selected === "agent") {
            const agentIds = agentToAdd.map(agent => agent.id);
            if (agentIds && agentIds.length > 0) {
                filterResponse = await getChatLogByAgents(agentIds, page)
                response = await getChatLog(userIds, projectIds, agentIds, page)
                chatlog = response.chatlogs;
                filterChatlog = filterResponse.chatlogs;
                setTotalPages(response.num_pages);
                const agentUsers = new Set(filterChatlog.map(filterchatlog => filterchatlog.user));
                const userProjects = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.project));
                const filteredUsers = allUsers.filter(user => agentUsers.has(user.id));
                const filteredProjects = allProjects.filter(project => userProjects.has(project.id));
                setFilteredUsers(filteredUsers.length > 0 ? filteredUsers : []);
                setFilteredProjects(filteredProjects.length > 0 ? filteredProjects : []);
            }
        }
        else if (selected === "project") {
            const projectIds = projectToAdd.map(project => project.id);
            if (projectIds && projectIds.length > 0) {
                filterResponse = await getChatLogByProject(projectIds, page)
                response = await getChatLog(userIds, projectIds, agentIds, page)
                chatlog = response.chatlogs;
                filterChatlog = filterResponse.chatlogs;
                setTotalPages(response.num_pages);
                const projectUsers = new Set(filterChatlog.map(filterchatlog => filterchatlog.user));
                const projectAgents = new Set(filterChatlog.map(filterchatlog => filterchatlog.agent.id));
                const filteredUsers = allUsers.filter(user => projectUsers.has(user.id));
                const filteredAgents = allAgents.filter(agent => projectAgents.has(agent.id));
                setFilteredUsers(filteredUsers.length > 0 ? filteredUsers : []);
                setFilteredAgents(filteredAgents.length > 0 ? filteredAgents : []);
            } else {
                chatlog = [];
            }
        }

        const mappedData = chatlog.map(item => {
            const matchingAgent = allAgents.find(agent => agent.id === item.agent.id);
            return {
                ...item,
                agent_name: matchingAgent ? matchingAgent.agent_name : null,
            };
        });
        setChatLog(mappedData)
        setChatLoading(false)
    }
    const handleAddFilter = () => {
        if (addFilter) {
            setAddFilter(false)
            setSelectedLiked("all")
            setSelectedCommented("all")
        } else {
            setAddFilter(true)
        }

    }

    useEffect(() => {

        const agentIds = agentToAdd.map(agent => agent.id);
        const userIds = userToAdd.map(user => user.id);
        const projectIds = projectToAdd.map(project => project.id);
        const filtered = chatLog
            .filter(item => {
                if (selectedLiked === "good response") {
                    return item.hasUpvoteOrDownvote && item.feedback && Array.isArray(item.feedback) && item.feedback.some(feedback => feedback.is_upvote);
                } else if (selectedLiked === "bad response") {
                    return item.hasUpvoteOrDownvote && item.feedback && Array.isArray(item.feedback) && item.feedback.some(feedback => feedback.is_downvote);
                } else {
                    return true;
                }
            })
            .filter(item => {
                if (selectedCommented === "feedback") {
                    return item.hasComments;
                } else if (selectedCommented === "no feedback") {
                    return !item.hasComments;
                } else {
                    return true;
                }
            })
            .filter(item => {
                if (selectedQuerySource === "web") {
                    return item.query_source === "web";
                } else if (selectedQuerySource === "MS Teams") {
                    return item.query_source === "MS Teams";
                } else if (selectedQuerySource === "Chat Widget") {
                    return item.query_source === "Ejento-Chat";
                } else {
                    return true;
                }
            })
        setFilteredChatLog(filtered)
        const mappedDataa = filtered.map(item => {
            let references: string = ''
            if (item.response.references.length > 0) {
                references = JSON.stringify(item.response.references?.map(item => ({ [item.number]: item.url })));
            }
            return {
                agent_name: item.agent_name,
                question: item.question,
                response: item.response.answer,
                references: references,
                created_on: item.created_on,
                created_by: item.created_by,
                vote_timestamp: item.feedback[0]?.modified_on,
                upvote: item.feedback[0]?.is_upvote,
                downvote: item.feedback[0]?.is_downvote,
                comments: item.comments.map(comment => comment.comment).join(', '),
            };
        });


        setTop3FieldsData(mappedDataa);
    }, [chatLog])

    const handleCitationDownload = (filePath: string) => {
        if (filePath && filePath.length >= 35 && filePath.length <= 60 && filePath.includes('-') && filePath.split('-').length === 6) {
            window.open(filePath, '_blank');
        } else {
            window.open(filePath, '_blank');
        }
    }
    (window as any).handleCitationDownload = (filePath: string) => {
        if (filePath && filePath.length >= 35 && filePath.length <= 60 && filePath.includes('-') && filePath.split('-').length === 6) {
            window.open(filePath, '_blank');
        } else {
            window.open(filePath, '_blank');
        }
    };

    const handleDownload = (func: any) => {
        handleCitationDownload(func.slice(24, -2))
    }
    function parseText(answer: any, agentType: string, agentId: number) {
        const sanitizedAnswerHtml = DOMPurify.sanitize(answer.response.answer);
        let result = '';
        let counter = 0;

        let replacedString = sanitizedAnswerHtml;
        if (agentType === 'Tutor') {
            const pattern = /\\\\\[([\s\S]*?)\\\\\]|\\\[([^]*?)\\\]|\\\(([^]*?)\\\)|\n\n\\\\text\(([^]*?)\)\n\n|\\\\text\(([^]*?)\)|\\\((.*?)\\\)/g;
            replacedString = sanitizedAnswerHtml.replace(pattern, (match, p1, p2, p3, p4, p5, p6) => {
                const cleanedMatch = (p1 || p2 || p3 || p4 || p5 || p6).replace(/\\\\/g, '\\');
                return `<ul id='mathEquation'>${cleanedMatch}</ul>`;
            });
        }
        // Define the regex pattern to match the desired format [number]
        const pattern = /\[(\d+)\]/g;
        // Use replace method with a callback function to process the matched patterns
        result = replacedString.replace(pattern, (match, p1, offset, str) => {
            const processedPattern = processPattern(p1, counter, answer.response.references, agentId.toString());
            return processedPattern;
        });

        return result;
    }

    function processPattern(pattern: string, counter: number, references: any, agentId:string) {

        const part = pattern.substring(1, pattern.length - 1);
        const referenceItem = references.find((x) => parseInt(part) == x.number);
        if (referenceItem) {
            const path = getCitationFilePath(referenceItem.url);
            if (referenceItem.url.includes('https://')) {
                counter++
                return `<a href="${referenceItem.url}" target="_blank"><sup>${referenceItem.order}</sup></a>`;
            }
            else {
                counter++
                return `<a onClick="handleCitationDownload('${path}?agentId=${encrypt(agentId)}&email=${encrypt(userEmail)}')"><sup>${referenceItem.order}</sup></a>`;
            }

        }

        return ``;
    }

    const handleDeleteChip = (option: any) => {
        const users = userToAdd.filter((userEmail) => userEmail !== option)
        setUserToAdd(users)
        if (users.length === 0 && selected === 'user') {
            setAgentToAdd([])
        }
    }

    const handleDeleteAgentChip = (option: { id: number, name: string }) => {
        const agents = agentToAdd.filter(agent => agent.id !== option.id);
        setAgentToAdd(agents)
        if (agents.length === 0 && selected === 'agent') {
            setUserToAdd([])
        }

    };
    const handleDeleteProjectChip = (option: { id: number, name: string }) => {
        const projects = projectToAdd.filter(agent => agent.id !== option.id);
        setProjectToAdd(projects)
        if (projects.length === 0 && selected === 'project') {
            setProjectToAdd([])
        }

    };
    useEffect(() => {
        const closeButton = document.getElementsByClassName("MuiAutocomplete-clearIndicator1")[0];
        const handleCloseClick = () => {
            reset()
        };

        if (closeButton) {
            closeButton.addEventListener("click", handleCloseClick);
        }

        return () => {
            if (closeButton) {
                closeButton.removeEventListener("click", handleCloseClick);
            }
        };
    });

    const splitEmailAndId = (option) => {
        const lastIndex = option.lastIndexOf('-');
        if (lastIndex !== -1) {
            return [option.slice(0, lastIndex), option.slice(lastIndex + 1)];
        }
        return [option, null]; // In case there is no hyphen, which shouldn't happen with your data structure
    };
    const splitProjectNameAndId = (option) => {
        const lastIndex = option.lastIndexOf('-');
        if (lastIndex !== -1) {
            return [option.slice(0, lastIndex), option.slice(lastIndex + 1)];
        }
        return [option, null];
    };
    return (
        <>
            <div style={{ marginLeft: "4%", marginBottom: '3%', display: 'flex', minWidth: '90%', alignItems: "flex-start", justifyContent: "center", flexWrap: 'wrap' }}>
                <div className={`ml-6 mb-6 me-2 mt-1`}>
                    <button type="button" className="btn btn-icon btn-secondary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" onClick={handleAddFilter}>
                        <Tooltip title={`${addFilter ? "Remove" : "Add"} Advanced Filter`}><FilterListIcon className={`${styles.reset}`} /></Tooltip>
                    </button>
                </div>

                <div className={` mb-6 ${styles.inline}`}>
                    <FormControl sx={{ mr: 1, padding: "0px" }}>
                        <InputLabel id="demo-multiple-checkbox-label" >
                            Filter By
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            required
                            value={selected}
                            onChange={handleFilter}
                            input={<OutlinedInput label="Filter" />}

                            sx={{

                                '.MuiSelect-icon': {
                                    top: "calc(50% - 8px)", // Adjust icon position as needed
                                }
                            }}

                        >
                            {filterOptions.map((option, index) => (
                                <MenuItem key={index} value={option} className={`MuiMenuItem`} >
                                    {option.charAt(0).toUpperCase() + option.slice(1)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className={`${styles.helper}`}>Filter by choice</FormHelperText>
                    </FormControl>
                </div>

                {/* users filter  */}
                <div className={` mb-6 ${styles.inline} me-2`}  >
                    <FormControl sx={{ marginRight: "1%", marginTop: agentToAdd.length > 0 ? '0px' : '0.7%' }}  >
                        <Autocomplete
                            size="small"
                            limitTags={1}
                            disableCloseOnSelect
                            disableClearable

                            disabled={selected === 'agent' && agentToAdd.length === 0 || selected === 'project' && projectToAdd.length === 0}
                            style={{ cursor: 'pointer', minWidth: "20rem", width: userToAdd.length > 0 ? "fit-content" : '20rem', maxWidth: '20rem', backgroundColor: activeTheme === 'dark' ? '#f0f0f0' : 'inherit', }}
                            multiple
                            inputValue={userInputValue}
                            renderTags={(value, getTagProps) =>
                                userToAdd.map((option, index) => (
                                    <Chip
                                        style={{ margin: '1px' }}
                                        key={option.id}  // It's better to use unique ids for keys instead of index
                                        label={option.email}  // Display only the email part in the chip
                                        onDelete={() => handleDeleteChip(option)}

                                    />
                                ))
                            }
                            onInputChange={(event, newInputValue) => {
                                setUserInputValue(newInputValue);
                            }}
                            // options={filteredUsers.map((user) => `${user.email}-${user.id}`)}
                            options={filteredUsers.map((user) => `${user.email}-${user.id}`)}
                            filterOptions={(options, { inputValue }) => {
                                return options.filter(option =>
                                    option.toLowerCase().includes(inputValue.toLowerCase())
                                );

                            }}
                            sx={{
                                ".MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                                    padding: "13px",
                                },
                                ".MuiInputBase-root": {
                                    borderRadius: "7px !important", width: "100%",
                                },
                                ".MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiInputLabel-root": {
                                    marginTop: "3%"
                                }

                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Users"
                                    size="small"
                                    style={{ width: "100%", display: 'flex', alignItems: 'center' }}
                                    variant={activeTheme === 'dark' ? 'filled' : 'outlined'}
                                    InputLabelProps={{
                                        style: {
                                            padding: '4px'  // Example padding
                                        }
                                    }}
                                />
                            )}

                            renderOption={(props, option, { index }) => {
                                const [email, id] = splitEmailAndId(option);
                                return (
                                    <li key={index} {...props} style={{ padding: "0px", borderBottom: "0.2px solid lightgray", backgroundColor: 'white' }} >
                                        <Checkbox
                                            icon={uncheckedIcon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 3 }}
                                            checked={userToAdd.some(user => user.id === parseInt(id))}
                                            title={option} // Keep the combined email-id as the title
                                            onChange={() => handleUserCheckboxChange(email, parseInt(id))} // Update selected values on checkbox change
                                        />
                                        <div style={{ display: "flex", color: "gray", fontSize: "1rem", minHeight: '100%', minWidth: "100%" }} onClick={() => handleUserCheckboxChange(email, parseInt(id))} >
                                            {email}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                        <FormHelperText className={styles.helper} >The User you want to look up</FormHelperText>
                    </FormControl>
                </div>

                {/* agents filter  */}
                <div className={` mb-6 ${styles.inline}`}>
                    <FormControl sx={{ marginRight: "1%", marginTop: agentToAdd.length > 0 ? '0px' : '0.7%' }}>
                        <Autocomplete
                            size="small"
                            limitTags={1}
                            disableCloseOnSelect
                            disabled={selected === 'user' && userToAdd.length === 0 || selected === 'project' && projectToAdd.length === 0}
                            disableClearable
                            style={{ cursor: 'pointer', minWidth: "20rem", width: agentToAdd.length > 0 ? "fit-content" : '20rem', maxWidth: '20rem', backgroundColor: activeTheme === 'dark' ? '#f0f0f0' : 'inherit' }}
                            multiple
                            renderTags={(value: string[], getTagProps) =>
                                agentToAdd.map((option: any, index: number) => (
                                    <Chip
                                        style={{ margin: '1px' }}
                                        key={index}
                                        label={option.name}
                                        onDelete={() => handleDeleteAgentChip(option)}
                                    />
                                ))
                            }
                            inputValue={inputValues}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            options={filteredAgents.map((agent) => `${agent.agent_name}-${agent.id}`)}
                            filterOptions={(options, { inputValue }) => {
                                return options.filter(option =>
                                    option.toLowerCase().includes(inputValue.toLowerCase())
                                );

                            }}
                            sx={{
                                ".MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                                    padding: "13px", 
                                },
                                ".MuiInputBase-root": {
                                    borderRadius: "7px !important", width: "100%"
                                }

                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Agents"
                                    size="small"
                                    style={{ width: "100%", display: 'flex', alignItems: 'center' }}
                                    variant={activeTheme === 'dark' ? 'filled' : 'outlined'}
                                    InputLabelProps={{
                                        style: {
                                            padding: '4px' 
                                        }
                                    }}
                                />
                            )}

                               renderOption={(props, option, { selected, index }) => {
                                const idPart = option.substring(option.lastIndexOf('-') + 1);
                                const displayName = option.substring(0, option.lastIndexOf('-'));
                           
                                return (
                                    <li
                                        {...props}
                                        key={index}
                                        style={{ padding: "0px", borderBottom: "0.2px solid lightgray", backgroundColor: 'white' }}
                                    >
                                        <Checkbox
                                            icon={uncheckedIcon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 3 }}
                                            checked={agentToAdd.some(agent => agent.id === parseInt(idPart))}
                                            onChange={() => handleAgentCheckboxChange(displayName, parseInt(idPart))}
                                        />
                           
                                        <div
                                            style={{
                                                display: "flex",
                                                color: "gray",
                                                fontSize: "1rem",
                                                minHeight: '100%',
                                                minWidth: "100%",
                                                whiteSpace: 'normal', 
                                                wordWrap: 'break-word' 
                                            }}
                                            onClick={() => handleAgentCheckboxChange(displayName, parseInt(idPart))}
                                        >
                                            {displayName}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                        <FormHelperText className={styles.helper}>The Agent you want to look up</FormHelperText>
                    </FormControl>
                </div>
                {/* projects filter */}
                <div className={`mb-6 ${styles.inline}`}>
                    <FormControl sx={{ marginRight: '1%', marginTop: projectToAdd.length > 0 ? '0px' : '0.7%' }}>
                        <Autocomplete
                            size="small"
                            limitTags={1}
                            disableCloseOnSelect
                            disabled={selected === 'user' && userToAdd.length === 0 || selected === 'agent' && agentToAdd.length === 0}
                            disableClearable
                            style={{
                                cursor: 'pointer',
                                minWidth: '20rem',
                                width: projectToAdd.length > 0 ? 'fit-content' : '20rem',
                                maxWidth: '20rem',
                                backgroundColor: activeTheme === 'dark' ? '#f0f0f0' : 'inherit',
                            }}
                            multiple
                            renderTags={(value: string[], getTagProps) =>
                                projectToAdd.map((option: any, index: number) => (
                                    <Chip
                                        style={{ margin: '1px' }}
                                        key={index}
                                        label={option.name}
                                        onDelete={() => handleDeleteProjectChip(option)}
                                    />
                                ))
                            }
                            inputValue={projectInputValue}
                            onInputChange={(event, newInputValue) => {
                                setProjectInputValue(newInputValue);
                            }}
                            options={filteredProjects.map((project) => `${project.project_name}-${project.id}`)}
                            filterOptions={(options, { inputValue }) => {
                                return options.filter(option =>
                                    option.toLowerCase().includes(inputValue.toLowerCase())
                                );
                            }}
                            sx={{
                                ".MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                                    padding: '13px', // Add padding here
                                },
                                '.MuiInputBase-root': {
                                    borderRadius: '7px !important',
                                    width: '100%',
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Projects"
                                    size="small"
                                    style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                                    variant={activeTheme === 'dark' ? 'filled' : 'outlined'}
                                    InputLabelProps={{
                                        style: {
                                            padding: '4px', // Example padding
                                        },
                                    }}
                                />
                            )}
                            renderOption={(props, option, { selected, index }) => {
                                const [projectName, projectId] = splitProjectNameAndId(option);
                                return (
                                    <li {...props} key={index} style={{ padding: '0px', borderBottom: '0.2px solid lightgray', backgroundColor: 'white' }}>
                                        <Checkbox
                                            icon={uncheckedIcon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 3 }}
                                            checked={projectToAdd.some(project => project.id === parseInt(projectId))}
                                            onChange={() => handleProjectCheckboxChange(projectName, parseInt(projectId))}
                                            title={option}
                                        />
                                        <div
                                            style={{ display: 'flex', color: 'gray', fontSize: '1rem', minHeight: '100%', minWidth: '100%' }}
                                            onClick={() => handleProjectCheckboxChange(projectName, parseInt(projectId))}
                                        >
                                            {projectName}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                        <FormHelperText className={styles.helper}>The Project you want to look up</FormHelperText>
                    </FormControl>
                </div>
                <div className={` mb-6 mt-1 ${styles.inline}`} >

                    <button type="button" className="btn btn-icon btn-secondary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" onClick={reset}>
                        <Tooltip title='Reset Filters'><RefreshIcon className={`${styles.reset}`} /></Tooltip>
                    </button>
                </div>
                <div className={` mb-6 mt-1 ${styles.inline}`} style={{ marginLeft: "2px" }}>
                    <button type="button" className="btn btn-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" onClick={() => exportToExcel(top3FieldsData, 'chatlogs')}>
                        <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />Export Chats
                    </button>
                </div>



            </div >

            {addFilter ? (
                <div className={`mb-6`} style={{
                    backgroundColor: activeTheme === 'dark' ? '#0d282e' : 'transparent',
                    padding: '10px 10px 0 10px',
                    borderRadius: '8px',
                    marginBottom: '1%',
                    marginTop: "-2%",
                    width: "100%",
                    display: "flex",
                    transition: 'opacity 0.5s ease-in-out', // Add a transition property
                    opacity: 1, // Initially set opacity to 1
                    justifyContent: 'center',

                }}>
                    <div className={`mb-6`} style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* Liked Select */}
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="liked-checkbox-label" style={{ fontSize: "11px", color: activeTheme === 'dark' ? 'white' : 'black' }}>
                                Filter by Response
                            </InputLabel>
                            <Select
                                labelId="liked-checkbox-label"
                                id="liked-checkbox"
                                required
                                value={selectedLiked}
                                onChange={handleLikedFilter}
                                input={<OutlinedInput label="Liked" />}
                                style={{ color: activeTheme === 'dark' ? 'white' : 'black' }}
                            >
                                {likedFilterOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText className={styles.helper}>Select Type of Response</FormHelperText>
                        </FormControl>

                        {/* Commented Select */}
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="commented-checkbox-label" style={{ fontSize: "11px", color: activeTheme === 'dark' ? 'white' : 'black' }}>
                                Filter By Feedback
                            </InputLabel>
                            <Select
                                labelId="commented-checkbox-label"
                                id="commented-checkbox"
                                required
                                value={selectedCommented}
                                onChange={handleCommentedFilter}
                                input={<OutlinedInput label="Commented" />}
                                style={{ color: activeTheme === 'dark' ? 'white' : 'black' }}
                            >
                                {commentedFilterOptions.map((option, index) => (
                                    <MenuItem key={index} value={option} >
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText className={styles.helper}>Select Type of Feedback</FormHelperText>
                        </FormControl>

                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="commented-checkbox-label" style={{ fontSize: "11px", color: activeTheme === 'dark' ? 'white' : 'black' }}>
                                Filter By Query Source
                            </InputLabel>
                            <Select
                                labelId="commented-checkbox-label"
                                id="commented-checkbox"
                                required
                                value={selectedQuerySource}
                                onChange={handleQueryFilter}
                                input={<OutlinedInput label="Commented" />}
                                style={{ color: activeTheme === 'dark' ? 'white' : 'black' }}
                            >
                                {querySourceFilterOptions.map((option, index) => (
                                    <MenuItem key={index} value={option} >
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText className={styles.helper}>Select Query Source</FormHelperText>
                        </FormControl>
                    </div>
                </div>) : <div style={{ opacity: 0, height: 0, overflow: 'hidden' }}>
                {/* Empty div to maintain space when the component is not visible */}
            </div>}


            {/*begin: chatlogs */}
            {!chatLoading ? (< div className={styles.logs} >{
                filteredChatLog.length > 0 ? (
                    filteredChatLog.map((chat, index) => {
                        const modifiedQuestion = chat.question.replace(/[.]$/, "?");
                        const modifiedAnswer = parseText(chat, chat.agent.agent_type.name, chat.agent.id)
                        return (
                            <Accordion key={index} defaultActiveKey="0" flush className={styles.panel}>
                                <Accordion.Item className={styles.panel} eventKey={index.toString()}>
                                    <Accordion.Header className={styles.panel} >{modifiedQuestion}</Accordion.Header>
                                    <Accordion.Body >
                                        <div className={styles.accordionBody}>
                                            <div className={styles.headingBar}>
                                                <div style={{ textTransform: 'capitalize' }}>
                                                    <span className={styles.header}>
                                                        <span className='symbol-label bg-light-danger'>
                                                            <SmartToySharpIcon color='error' fontSize='medium' />
                                                        </span>
                                                    </span>
                                                    {"   " + chat.agent_name}
                                                </div>
                                                <div><span className={styles.header}><AccountCircle color='info' fontSize='medium' /></span> {chat.created_by}</div>
                                            </div>

                                            <Markdown
                                                className="AnswerChat"
                                                remarkPlugins={[remarkGfm]}
                                                rehypePlugins={[rehypeRaw]}
                                                components={{
                                                    a(props) {
                                                        const { children, ...rest } = props;
                                                        if (props.node) {
                                                            const childElement = props.node.children[0] as {
                                                                type: string;
                                                                tagName: string;
                                                                properties: Record<string, any>;
                                                                children: any[]; // Update this with the actual structure of your children
                                                                position: Record<string, any>;
                                                            };
                                                            const childChildren = childElement.children;
                                                            if (childChildren?.length > 0 && childChildren[0].type === 'text') {
                                                                const value = childChildren[0].value;
                                                                if (props.href) {
                                                                    return (
                                                                        <a href={props.href} target="_blank"><sup>{value}</sup></a>
                                                                    );
                                                                } else if (props.onClick) {
                                                                    return (
                                                                        <a onClick={() => handleDownload(props.onClick)} target="_blank"><sup>{value}</sup></a>
                                                                    );
                                                                }
                                                            }   else {
                                                                return <a {...rest}>{children}</a>;
                                                            }
                                                        } 
                                                         
                                                         else {
                                                            return <a {...rest}>{children}</a>;
                                                        }
                                                    
                                                    },
                                                    code(props) {
                                                        const { children, className, node, ...rest } = props;
                                                        const match = /language-(\w+)/.exec(className || "");
                                                        return match ? (
                                                            <SyntaxHighlighter
                                                                PreTag="div"
                                                                children={String(children).replace(/\n$/, "")}
                                                                language={match[1]}
                                                                style={oneLight}
                                                            />
                                                        ) : (
                                                            <code {...rest} className={className}>
                                                                {children}
                                                            </code>
                                                        );
                                                    },
                                                    ul(props) {
                                                        const { children, node, ...rest } = props;
                                                        if (node) {
                                                            const id = node.properties?.id;
                                                            if (id === 'mathEquation') {
                                                                return <BlockMath>{children}</BlockMath>;
                                                            }
                                                        }
                                                        return <ul {...rest}>{children}</ul>;
                                                    },
                                                }}
                                            >
                                                {modifiedAnswer}
                                            </Markdown>

                                            <div className={styles.upvoteDownvoteContainer}>
                                                <span style={{ color: chat.feedback[0]?.is_upvote ? 'green' : 'lightgrey' }}>
                                                    <ThumbUpOutlinedIcon fontSize='small' />
                                                </span>
                                                <span style={{ color: chat.feedback[0]?.is_downvote ? 'red' : 'lightgrey' }}>
                                                    <ThumbDownOutlinedIcon fontSize='small' />
                                                </span>
                                                <span className={styles.commentIcon} onClick={() => handleFeedbackClick(index)}>
                                                    <ModeCommentOutlinedIcon fontSize='small' />
                                                </span>
                                                {showCommentModal && selectedChatIndex === index ? (
                                                    <Feedbacks
                                                        setShowFeedbackModal={setShowCommentModal}
                                                        showFeedbackModal={showCommentModal}
                                                        feedbackComments={chat.comments}
                                                        chat_id={chat.id}
                                                        user_email={chat.created_by}
                                                        isReadOnly={true}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    })
                ) : (!(userToAdd.length == 0 && agentToAdd.length == 0) && <p style={{ color: 'lightgrey', textAlign: 'center', fontSize: '2.3rem', marginTop: '10%' }}>No Chats Found</p>)
            }
            </div >) : (<div className={styles.loader}><CircularProgress /></div>)
            }
            {
                chatLog.length > 0 && totalPages! > 1 && (
                    <Stack spacing={2} justifyContent="center" alignItems="center" mt={2}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={(event, value) => handlePageChange(value)}
                            sx={{ button: { color: 'grey' } }}
                            variant='outlined'
                        />
                    </Stack>
                )
            }
        </ >
    );
}
